import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import Layout from '~components/layouts/Default';
import lautenschlagerLogo from '../media/partnerlogos/webdesigner-kassel-lautenschlager-logo.png';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Impressum - Unternehmensdaten gemäß § 5 TMG" desc="Im Impressum findest du alle relevanten Daten zu unserem Unternehmen gemäß § 5 TMG sowie unsere Möglichkeit für den Kontakt">
      <div className="bg-gray-50 py-8">
        <PageHeader title="Impressum" breadcrumb={[{ title: 'Impressum', link: '/impressum/', current: true }]} />
        <Container>
          <div className="prose prose-lg mx-auto mt-6">{parse(data.rechtliches.text)}</div>
        </Container>
        <Container>
          <div className="mx-auto mt-3 max-w-xl bg-gray-100 p-2 text-center">
            <p className="mb-1 text-xs">Website erstellt von:</p>
            <a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
              <img src={lautenschlagerLogo} title="Webdesign Kassel - Lautenschlager Marketing & Entwicklung" alt="Webdesign Kassel - Lautenschlager Marketing & Entwicklung" className="mx-auto" />
              <br />
              Webdesign Kassel - Lautenschlager Marketing & Entwicklung
            </a>
          </div>
        </Container>
        <Container>
          <div className="prose">
            <ul>
              <li>
                Line-Art Bilder "Wo stehst du?" auf Kursseite von <a href="https://www.vecteezy.com/free-vector/human">Human Vectors by Vecteezy</a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    rechtliches(title: { eq: "Impressum" }) {
      text
    }
  }
`;
